import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InputComponent, MightyComponentsModule } from '@mightyhealth/angular-web-components';
import { AccordionComponent } from './accordion/accordion.component';
import { BackComponent } from './back/back.component';
import { CardComponent } from './card/card.component';
import { FoodCardComponent } from './food-card/food-card.component';
import { FooterComponent } from './footer/footer.component';
import { GroupComponent } from './group/group.component';
import { ContextMenuComponent } from './header/context-menu/context-menu.component';
import { HeaderComponent } from './header/header.component';
import { LoadingBoxComponent } from './loading-box/loading-box.component';
import { LoginComponent } from './login/login.component';
import { MarketingCardComponent } from './marketing-card/marketing-card.component';
import { EventCancelledComponent } from './modals/event-cancelled/event-cancelled.component';
import { EventReservedComponent } from './modals/event-reserved/event-reserved.component';
import { LoginModalComponent } from './modals/login-modal/login-modal.component';
import { ShareComponent } from './modals/share/share.component';
import { PaymentCartComponent } from './payment-cart/payment-cart.component';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { SnakeCaseToTextPipe } from './pipes/snake-case-to-text.pipe';
import { PostActionsComponent } from './post-actions/post-actions.component';
import { PostCommentComponent } from './post-comment/post-comment.component';
import { PostFullComponent } from './post-full/post-full.component';
import { PostHeaderComponent } from './post-header/post-header.component';
import { PostComponent } from './post/post.component';
import { ProductLandingPageComponent } from './product-landing-page/product-landing-page.component';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { SpecialistCardComponent } from './specialist-card/specialist-card.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { TodayTaskCardComponent } from './today-task-card/today-task-card.component';
import { TodayTasksProgressCheckComponent } from './today-tasks-progress-check/today-tasks-progress-check.component';
import { WorkoutComponent } from './workout/workout.component';

@NgModule({
  declarations: [
    BackComponent,
    PostFullComponent,
    RelativeDatePipe,
    PostHeaderComponent,
    PostActionsComponent,
    PostCommentComponent,
    PostComponent,
    GroupComponent,
    WorkoutComponent,
    FoodCardComponent,
    HeaderComponent,
    MarketingCardComponent,
    LoginModalComponent,
    LoginComponent,
    EventReservedComponent,
    EventCancelledComponent,
    ShareComponent,
    LoadingBoxComponent,
    ProductLandingPageComponent,
    ContextMenuComponent,
    CapitalizeFirstLetterPipe,
    SnakeCaseToTextPipe,
    DateFormatPipe,
    TestimonialComponent,
    CardComponent,
    AccordionComponent,
    ProgressCircleComponent,
    TodayTaskCardComponent,
    TodayTasksProgressCheckComponent,
    PaymentCartComponent,
    PromoCodeComponent,
    SpecialistCardComponent,
  ],
  imports: [CommonModule, RouterModule, MightyComponentsModule, FormsModule, FooterComponent, InputComponent],
  exports: [
    BackComponent,
    PostFullComponent,
    PostHeaderComponent,
    PostActionsComponent,
    PostCommentComponent,
    PostComponent,
    GroupComponent,
    WorkoutComponent,
    FoodCardComponent,
    HeaderComponent,
    MarketingCardComponent,
    LoginModalComponent,
    LoginComponent,
    EventReservedComponent,
    EventCancelledComponent,
    ShareComponent,
    LoadingBoxComponent,
    ProductLandingPageComponent,
    ContextMenuComponent,
    CapitalizeFirstLetterPipe,
    SnakeCaseToTextPipe,
    DateFormatPipe,
    TestimonialComponent,
    CardComponent,
    AccordionComponent,
    ProgressCircleComponent,
    TodayTaskCardComponent,
    TodayTasksProgressCheckComponent,
    PaymentCartComponent,
    PromoCodeComponent,
    SpecialistCardComponent,
  ],
})
export class ComponentsModule {}
