import { EnvironmentName } from './environment-name';
import { EnvironmentDef } from './environments';

export const environment: EnvironmentDef = {
  production: true,
  environmentName: EnvironmentName.PROD,
  API_URL: 'https://api.mightyhealth.com/v2/auth',
  API_UNAUTH_URL: 'https://api.mightyhealth.com',
  API_UNAUTH_LEGACY_URL: 'https://api.mightyhealth.com/v2/unauth',
  BASE_URL: 'https://mightyhealth.com',
  SEGMENT_KEY: 'n6dcPTfWvRCHTHwL9yqqG6vTuIoTXs8A',
  STRIPE_KEY: 'pk_live_MxuPcrm20t7P8rUwPrkN3dPb00qbLCUGbY',
  GA_MEASUREMENT_ID: 'G-QJ069YQJ0J',
  firebase: {
    apiKey: 'AIzaSyC_MYWqCHhzU6ncMiMZGJqrESFa01ooGhw',
    authDomain: 'mighty-health.firebaseapp.com',
    databaseURL: 'https://mighty-health.firebaseio.com',
    projectId: 'mighty-health',
    storageBucket: 'mighty-health.appspot.com',
    messagingSenderId: '1055317642538',
    appId: '1:1055317642538:web:ec50beca4b26b20b10e253',
    measurementId: 'G-5WVSE21TQL',
  },
  KLAVIYO_CONFIG: {
    url: 'https://a.klaviyo.com/api',
    publicApiKey: 'YkpiqZ',
  },
  SENTRY_DSN: 'https://59d3f8cb639040c6aa756a7e6a0159c9@o350264.ingest.sentry.io/4504476181659648',
  ACUITY_APPT_TYPE: '60475360',
  ZOOM_MEETING_SDK_KEY: 'PvxBt4g6SyGteS3EBCiKg',
};
